import { Table } from "antd"
import { useState } from "react"
import style from "./style.module.scss"
import moment from "moment/moment"
import "react-loading-skeleton/dist/skeleton.css"
import { connect } from "react-redux"
import { getTradingCard } from "../../pages/TradingCard/redux/action"
const TradingCardTable = props => {
  const { tableData, loading, getTradingCard } = props

  const info = []
  tableData &&
    tableData.results?.map((item, index) => {

      const date = moment(item.date_joined).format("DD MMM YYYY")
      return info.push({
        key: index + 1,
        No: index + 1,
        ID: item.id,
        user_name: item.user_name,
        address_line1: item.address_line1 + ", " + item.address_line2,
        city: item.city,
        sport: item.sport_name,
        state: item.state,
        university: item.university_name,
        zip_code: item.zip_code,
        name: item.name
      })
    })

  //   const paginationConfig = {
  //   // position: ["Center"], // Specify the position of the pagination
  //   showSizeChanger: true, // Display options to change the page size
  //   // showQuickJumper: true, // Display quick jumper input
  //   pageSizeOptions: ["10", "20", "30"], // Available page size options
  //   defaultPageSize: 15, // Default page size
  //   total: info.length // Total number of items
  // }
  const paginationConfig = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30"],
    // defaultPageSize: 10,
    total: tableData.total_objects,
    current: tableData.current_page,
    pageSize: tableData.page_size,
    onChange: (page, pageSize) => {
      let query = ""

      if (page) {
        query += `?page=${page}`
      }

      if (pageSize) {
        if (query.length > 0) {
          query += `&no_of_pages=${pageSize}`
        } else {
          query += `?no_of_pages=${pageSize}`
        }
      }

      getTradingCard(query)
    }
  }

  return (
    <div className={style.tableSection}>
      <Table
        className={style.teamemberTable}
        columns={props.columns}
        dataSource={info}
        pagination={paginationConfig}
      />
    </div>
  )
}
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
  getTradingCard: data => dispatch(getTradingCard(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(TradingCardTable)
