// @ts-nocheck
import React, { useEffect, useState } from "react"
import HomeLayout from "../../layout/HomeLayout"
import { SearchOutlined, PlusOutlined } from "@ant-design/icons"
import { Input, Table, Tooltip, Divider, Row, Col, Select } from "antd"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import style from "./style.module.scss"

import ManagementTable from "../../Components/ManagementTable"
import warning from "../../assets/Images/warning.png"
import edit from "../../assets/Images/edit.svg"
import trash from "../../assets/Images/trash.svg"
import { connect, useDispatch, useSelector } from "react-redux"
import { getTradingCard } from "./redux/action"
import CommonButton from "../../common/button"
// import CreateTradingCardDrawer from "./CreactTradingCardDrawer"
import AntdModal from "../../Components/AntdModal"
import TradingCardTable from "../../Components/TradingCardTable"

const TradingCard = props => {
  const {
    getTradingCard,
    TradingCardData,
    requesting,

  } = props
  const dispatch = useDispatch()

  useEffect(() => {
    if (getTradingCard) {
      getTradingCard("")
    }
  }, [getTradingCard])
  console.log("getTradingCard", getTradingCard);

  const columns = [
    {
      title: "No.",
      dataIndex: "No",
      minWidth: "60px",
      width: "60px",
      render: text => {
        return <Tooltip title={text}>{text}</Tooltip>
      }
    },
    {
      title: "User",
      dataIndex: "user_name",

      render: (text) => {
        const capitalizedText = text.charAt(0).toUpperCase() + text.slice(1);
        return <Tooltip title={capitalizedText}>{capitalizedText}</Tooltip>;
      }
    },
    {
      title: "Name",
      dataIndex: "name",

      render: (text) => {
        // If text is NaN, null, or an empty string, show "-"
        const validText = text === null ? "-" : text;

        const capitalizedText = validText?.charAt(0).toUpperCase() + validText?.slice(1);

        return <Tooltip title={capitalizedText}>{capitalizedText}</Tooltip>;
      }
    },



    // {
    //   title: "City",
    //   dataIndex: "city",
    //   render: text => {
    //     return <Tooltip title={text}>{text}</Tooltip>
    //   }
    // },


    // {
    //   title: "State",
    //   dataIndex: "state",
    //   render: text => {
    //     return <Tooltip title={text}>{text}</Tooltip>
    //   }
    // },
    // {
    //   title: "zip code",
    //   dataIndex: "zip_code",
    //   render: text => {
    //     return <Tooltip title={text}>{text}</Tooltip>
    //   }
    // },
    {
      title: "Sport",
      dataIndex: "sport",
      render: text => {
        return <Tooltip title={text}>{text}</Tooltip>
      }
    },
    {
      title: "University",
      dataIndex: "university",
      render: text => {
        return <Tooltip title={text}>{text}</Tooltip>
      }
    },

    // {
    //   title: " Shipping Address",
    //   dataIndex: "address_line1",

    //   render: text => {
    //     return <Tooltip title={text}>{text}</Tooltip>
    //   }
    // },




  ]
  return (
    <HomeLayout heading="Trading Card">
      <section
        className={style.managementContainer}
        style={{ overflow: "hidden !important" }}
      >
        <Row>
          <Col span={24}>
            <Divider />
            {requesting ? (
              <Table
                rowKey="key"
                className={style.teamemberTableSkelton}
                pagination={false}
                dataSource={[...Array(5)].map((_, index) => ({
                  key: `key${index}`
                }))}
                columns={columns?.map(column => {
                  return {
                    ...column,
                    render: function renderPlaceholder() {
                      return (
                        <>
                          <SkeletonTheme
                            baseColor="#1f2732"
                            highlightColor="#5c6f87"
                          >
                            <Skeleton width="70%" height={10} />
                          </SkeletonTheme>
                        </>
                      )
                    }
                  }
                })}
              />
            ) : (
              <TradingCardTable columns={columns} tableData={TradingCardData} />
              // <ManagementTable columns={columns} tableData={TradingCardData} />
            )}
          </Col>
        </Row>
      </section>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  TradingCardData: state.TradingCard.data,
  requesting: state.TradingCard.loader,

})

const mapDispatchToProps = dispatch => ({
  getTradingCard: data => dispatch(getTradingCard(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(TradingCard)
