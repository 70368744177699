import { all, takeLatest, put, call } from "redux-saga/effects"
import { CREATE_USER, DELETE_USER, GET_USER, UPDATE_USER } from "./actionTypes"
import {
  getUserSuccess,
  getUserFail,
  createUserFailure,
  createUserSuccess,
  getUser,
  deleteUserFailure,
  deleteUserSuccess,
  editUserFailure,
  editUserSuccess
} from "./action"
import XHR from "../../../utils/XHR"
import { BASE_URL } from "../../../config/app"
import { toast } from "react-hot-toast"
import { toast as toastify } from "react-toastify"

async function getUserApi(data) {
  const datapass = data ? `${data}` : ""
  const id = data.selectUniversty ? `?university=${data.selectUniversty}` : data
  // const id = `?university=${data.id}`
  // console.log("id", id.valueOf)
  const URL = `${BASE_URL}/api/v1/admin/get_all_users/${id}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* onPostLoginData({ data }) {
  try {
    const response = yield call(getUserApi, data)
    yield put(getUserSuccess(response.data))
  } catch (error) {
    yield put(getUserFail(error.response))
  }
}

async function createUserData(data) {
  const URL = `${BASE_URL}/api/v1/admin/get_all_users/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Authorization: `token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* createUser({ data, onClose }) {
  try {
    const response = yield call(createUserData, data)
    yield put(createUserSuccess(response.data))
    yield put(getUser(""))
    toastify.success("User created successfully")
    onClose()
  } catch (e) {
    yield put(createUserFailure(e?.response?.data))

    let errorMessage = "Something went wrong"

    if (e.response?.data?.email) {
      errorMessage = e.response.data.email[0]
    }
    toastify.error(errorMessage)
  }
}

async function deleteUsereData(data) {
  const URL = `${BASE_URL}/api/v1/admin/get_all_users/${data.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Authorization: `token ${token}`
    },
    method: "DELETE",
    data: data.id
  }
  return XHR(URL, options)
}
function* deleteUserApi(data) {
  try {
    yield call(deleteUsereData, data.data)
    yield put(deleteUserSuccess())
    data.data.setDeleteModal(false)
    yield put(getUser(""))
    toast.success("User deleted successfully")
  } catch (e) {
    yield put(deleteUserFailure(e?.response?.data))
    toast.error("Something went wrong")
  }
}

async function editUserApi(data) {
  const URL = `${BASE_URL}/api/v1/admin/get_all_users/${data.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Authorization: `token ${token}`
    },
    method: "PATCH",
    data: data.body
  }
  return XHR(URL, options)
}
function* editUser({ data, onClose }) {
  try {
    const response = yield call(editUserApi, data)
    yield put(editUserSuccess(response.data))
    yield put(getUser(""))
    toastify.success("User updated successfully")
    onClose()
  } catch (e) {
    yield put(editUserFailure(e?.response?.data))

    let errorMessage = "Something went wrong"

    if (e.response?.data?.email) {
      errorMessage = e.response.data.email[0]
    }
    if (e.response?.data?.dob) {
      errorMessage = e.response.data.dob[0]
    }
    toastify.error(errorMessage)
  }
}

export default all([
  takeLatest(GET_USER, onPostLoginData),
  takeLatest(CREATE_USER, createUser),
  takeLatest(DELETE_USER, deleteUserApi),
  takeLatest(UPDATE_USER, editUser)
])
